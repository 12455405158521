import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 102,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Lato, sans-serif",
    secondary: "Arial, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      main: "#F7F7F1",
      light: "#FFFFFF",
      dark: "#090A11",
    },
    secondary: {
      main: "#1C1E29",
      light: "#48787B",
      dark: "#292C3D",
    },
    tertiary: {
      main: "#2C9ED0",
    },
    text: {
      primary: "#F7F7F1",
      secondary: "#B1B4C2",
      content: "#000000",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Lato", "Arial", sans-serif`,
  },
})

export default theme
